@media (min-width: 860px) {
  /*464px) {*/
  .fontStyling_choice {
    font-size: 15px;
  }
  .question_font {
    font-size: 20px;
  }

  #logo {
    height: 5vmin;
    padding-right: 20px;
  }

  #luup-embed .luup-4 {
    font-size: 17px;
    max-width: 580px;
  }

  #luup-embed-container {
    display: block;
    position: relative;

    width: 850px;
    max-width: 900vw;

    max-height: 90vh;
    margin: auto;
    padding-top: 5vmin;
  }

  /*
  .question_font {
    font-size: 25px;
  }
  .fontStyling_choice {
    font-size: 18px;
  }*/

  .question_font {
    font-size: 28px;
  }
  .fontStyling_choice {
    font-size: 20px;
  }

  .sub-heading {
    font-size: 14px;
  }

  .text_under_headiong {
    font-size: 18px;
  }

  .heading {
    font-size: 25px;
  }

  #luup-embed .luup-2-2 {
    width: 100%;
    height: 0.3em;
  }

  #luup-embed .luup-4 {
    font-size: calc(1 * 4vmin);
    max-width: calc(29 * 1 * 3.5vmin);
    box-sizing: border-box;
    border-radius: 0.5em;
    background: #fff;
    position: relative;
    overflow: hidden;
  }
}
