body {
  margin: 0;
  font-family: "Quicksand", sans-serif;
  background-color: #f3f5fe;
}

#luup-embed-container {
  display: block;
  position: relative;
  width: 560px;
  max-width: 90vw;
  max-height: 90vh;
  margin: auto;
  padding-top: 5vmin;
}

#luup-embed-container > *:not(#loader) {
  opacity: 1;
  transition: opacity 0.2s;
}

#luup-embed-container.loading > *:not(#loader) {
  opacity: 0;
}

#luup-embed-container #loader {
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  width: 260px;
  max-width: 50vw;
  opacity: 1;
  text-align: center;
  transition: opacity 0.2s, top 1s;
}

#luup-embed-container.loading #loader.waiting,
#luup-embed-container:not(.loading) #loader {
  top: -200px;
  opacity: 0;
}

#footer {
  padding: 16px 8px;
  position: relative;
}

#luup-embed .luup-2 {
  width: 100%;
  height: 0.25em;
  background: #ececec;
}

#luup-embed .luup-2-2 {
  width: 100%;
  height: 0.8em;
  background: #ececec;
}

#luup-embed .luup-3 {
  background: #301b92;
  height: 0.25em;
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

#luup-embed .luup-4 {
  font-family: "Quicksand", sans-serif;
  font-size: calc(1 * 4vmin);
  max-width: calc(29 * 1 * 3.5vmin);
  box-sizing: border-box;
  border-radius: 0.5em;
  background: #fff;
  position: relative;
  overflow: hidden;
}

#luup-embed .luup-5 {
  padding: 0 1em;
}

#footer.hidden {
  display: none;
}

#logo {
  display: inline-block;
  position: absolute;
  right: 8px;
  height: 5vmin;
  height: 10vmin;
}

@media (min-width: 560px) {
  #logo {
    height: 5vmin;
  }
}

.center {
  text-align: center;
}

button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  text-align: left;
  background-color: #fff;
  border: none;
  color: white;
  padding: 15px 32px;
  text-decoration: none;
  display: inline-block;
}

button:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
button:active {
  background: rgb(48, 27, 146, 0.5);
}

button:focus {
  outline: 0;
}
.openfield_styling {
  text-align: center;
}
.active {
  -webkit-animation: spin-cw 1s linear;
  -moz-animation: spin-cw 1s linear;
  animation: spin-cw 1s linear;
}

.circle_outline {
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid black;
  text-align: center;

  display: inline-block;
}
/*
.circle_placement {
  width: 40;
}
*/
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.dot {
  height: 12.5px;
  width: 12.5px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
}
.padding-left {
  padding-left: 15px;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  padding: 30px;
  text-align: left;
  background: white;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
  color: #424242;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.question-padding {
  padding-top: 10px;
}
.answer-padding {
  padding-top: 10px;
}
.sub-heading-padding {
  padding-top: 5px;
}
.sub-heading {
  font-size: 12px;
  color: #525154;
}
.heading {
  font-family: "Quicksand", sans-serif;
  font-size: 22px;
}
.text_under_headiong {
  font-family: "Nunito Sans", sans-serif;
  font-size: 15px;
}
.left-justified {
  text-align: left;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.padding-question {
  padding-left: 10px;
}
.question_font {
  color: #525154;
  font-family: "Quicksand", sans-serif;
  /*font-size: 16px;*/
  font-size: 23px;
}
.fontStyling_choice {
  color: #525154;
  font-family: "Quicksand", sans-serif;
  /* font-size: 13px;*/
  font-size: 18px;
  text-align: left;
}

.fontStyling_ending {
  color: #525154;
  font-family: "Quicksand", sans-serif;
  /* font-size: 13px;*/
  font-size: 18px;
  text-align: center;
}

.paddingForm {
  padding-left: 10px;
  padding-right: 10px;
}
.sizeOfSuccess {
  max-height: 500px;
  background-attachment: red;
}

.openfield_styling {
  text-align: center;
}
.download_app_button {
  background-color: #301b92;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(68, 73, 79, 0.55);
  cursor: pointer;
}
.download_app_button_font {
  color: #fff;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
}

.download_font_google_review {
  color: #000;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
}

.default_cursor {
  cursor: default;
}

.center_buttton {
  margin: auto;
  width: 50%;
  padding: 10px;
}
.button_google_i {
  /* width: 30px;
 height: 30px;*/
  font-size: 1.4em;
  color: #fff;
}

.googleimg {
  /*height: 25px;*/
  /* max-width: 50%;*/
  /*  width: auto;
  height: auto;*/
  cursor: pointer;
  width: 40%;
}

@media (min-width: 351px) {
  .googleimg {
    width: 70%;
  }
}
@media (min-width: 500px) {
  .googleimg {
    width: 60%;
  }
}

@media (min-width: 860px) {
  .googleimg {
    width: 40%;
  }
}

@media (max-width: 350px) {
  .googleimg {
    width: 100%;
  }
}

.true_center {
  background-color: red;
  width: 50%;
  margin: 0 auto;
}

.button_nps {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  box-shadow: 5px 5px #888888;
}
